import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModDailyHighlight from "../../../components/Chronology/Modules/ModDailyHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDotList from "../../../components/Chronology/Modules/ModDotList";
import { StrongGold } from "../../../components/Textstyles/Styles";

export const frontmatter = {
  title: "Día 45",
  week: "Semana 7",
  month: "abr",
  day: "27",
  monthNumber: "04",
  date: "2020-04-27",
  path: "/cronologia/semana-07#dia-27-abr",
};
const Day45 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDataEu fecha={"2020-04-27"} />
        <ModLethalityEu fecha={"2020-04-27"} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 2.793 nuevos positivos y 331 personas fallecidas.
          Permanecen hospitalizadas 114.081 personas, 33.931 más que el día
          anterior, y 2.143 reciben el alta. El número de casos ingresados en la
          UCI asciende a 10.372, lo que supone un aumento de 2.608 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 10,3% y la tasa de recuperados del 44%.
        </ModText>
        <ModDailyHighlight>
          <strong>España</strong>supera los <StrongGold>100.000</StrongGold>{" "}
          recuperados, el <StrongGold>47%</StrongGold> de los contagiados
        </ModDailyHighlight>
        <ModText>
          Hoy han comenzado las llamadas a 90.000 personas para que participen
          en el <strong>estudio de seroprevalencia</strong> diseñado por el
          Ministerio de Sanidad y el Instituto de Salud Carlos III (ISCIII), con
          el cual se estimará el porcentaje de población española con
          anticuerpos frente a COVID-19.
        </ModText>
        <ModText>
          Por otra parte, los resultados obtenidos del{" "}
          <strong>análisis de movilidad</strong> en España durante el estado de
          alarma, muestran una reducción considerable del uso del sistema de
          transportes.
        </ModText>
        <ModImage
          src="/images/svg/28-04-banner-datos-movilidad.svg"
          alt="evolución de la movilidad por confinamiento debido al covid-19"
        />
        <ModText>
          La <strong>Seguridad Social</strong> aprueba una moratoria que
          permitirá a las empresas y autónomos de 12 sectores económicos
          <strong>
            suspender durante seis meses las cotizaciones sociales
          </strong>
          , sin ningún tipo de interés, pagaderas en los meses de mayo, junio y
          julio.
        </ModText>
        <ModImage
          src="/images/svg/27-04-turismo-eu.svg"
          alt="plan de recuperacion del sector turístico"
        />
        <ModText>
          A iniciativa de España, nueve países de la Unión Europea proponen un{" "}
          <strong>
            Plan de Recuperación europeo para apoyar al sector turístico
          </strong>
          . La propuesta recoge tres pilares clave:
        </ModText>
        <ModDotList
          text1="Establecer protocolos homogéneos para
          garantizaar una movilidad segura"
          text2="Atender la problemática específica de
          los países y territorios más afectados"
          text3="Habilitar fondos suficientes para ayudar
          a las empresas y trabajadores del sector turístico"
        />
        <ModReferenceList title="GUÍAS Y DOCUMENTOS PUBLICADOS">
          <ReferenceRow
            link="https://www.mitma.gob.es/ministerio/covid-19/evolucion-movilidad-big-data"
            name="Análisis de la movillidad durante el estado de alrama"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day45;
